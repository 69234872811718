<template>
  <div class="container">
    <div class="content">
      <h1>HID Parent App</h1>
      <a href="https://passport.hidglobal.com/" class="button">3D Passport</a>
      <a href="https://dtcquiz.hidglobal.com/" class="button">DTC Quiz</a>
      <a href="https://dtcquiz.hidglobal.com/" class="button">Datapage Puzzle</a>
      <a href="https://dtcquiz.hidglobal.com/" class="button">Solutions Builder</a>
    </div>
  </div>
</template>

<script>
/* import HelloWorld from "./components/HelloWorld.vue"; */

export default {
  name: "App",
  components: {},
};
</script>

<style>
* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  touch-action: none;
  -ms-touch-action: none;
}

.container {
  height: 100vh;
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--Gradient-01, linear-gradient(180deg, #002d56 38.38%, #0057b7 132.81%));
}

.content {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: fit-content;
}

h1 {
  font-family: "neue-haas-unica", sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #fff;
  font-size: 2.5rem;
  line-height: normal;
  margin-bottom: 30px;
  white-space: pre;
  text-align: center;
  max-width: 600px;
}

a.button {
  background-color: #edc417;
  color: #002d56;
  font-family: "neue-haas-unica", sans-serif;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 600;
  width: 25rem;
  height: 3.5rem;
  border-radius: 6rem;
  border: none;
  margin: 10px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}
</style>
